<template>
  <div id="app">

    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  watch:{

  }
}
</script>

<style>
@import "assets/css/reset.css";
/*#app{*/
/*  height: 100vh;*/
/*  width: 100vh;*/
/*}*/
</style>
